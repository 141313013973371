
if(document.querySelector('section.main')){
  if (window.matchMedia('(min-width: 1366px)').matches) {

    const historyStartSection = document.querySelector('.js-history-start');
    const main = document.querySelector('.main');

    window.addEventListener("scroll", function (event) {
      if (window.pageYOffset <= window.innerHeight) {
        var top = this.pageYOffset;

        var layers = document.querySelectorAll(".js-layer");
        var layer, speed, yPos;
        for (var i = 0; i < layers.length; i++) {

          layer = layers[i];
          speed = layer.getAttribute('data-speed');
          var yPos = -(top * speed / 100);
          layer.setAttribute('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px)');

          if (layers[3]) {
            // main.style.marginBottom = `${yPos}px`
          }

        }
      }
    });


    const btn = document.querySelector('.js-down');

    let btnDown;
    let topCord = btn.getBoundingClientRect();
    let currentPos;

    btn.addEventListener('mousedown', (e) => {
      btnDown = true;
      topCord = e.pageY;
    });

    window.addEventListener('mouseup', () => {
      btnDown = false;
      if (currentPos < 0 || currentPos < -60) {
        currentPos = 0;
        btn.classList.add('active');
        btn.style.transform = `translateY(${currentPos}px)`;
        setTimeout(() => {
          btn.classList.remove('active');
        }, 1500);
      }


    });

    const offsetTopAnchor = $('.js-history-start').offset().top;


    document.querySelector('.js-drgn').addEventListener('mousemove', (e) => {
      // btn.style.transform = `translateY(${e.pageY - topCord})px`;
      if (btnDown === true) {
        currentPos = e.pageY - topCord;
        if (currentPos < -60) {
          btn.classList.add('active');
          currentPos = 0;
          window.scrollTo({
            top: offsetTopAnchor,
            behavior: 'smooth',
          });
          setTimeout(() => {
            btn.classList.remove('active');
          }, 1500);
        }
        if(currentPos > 0){
          btn.style.transform = `translateY(${0}px)`;
          currentPos = 0;
        }
        btn.style.transform = `translateY(${currentPos}px)`;
      }
    });

    document.querySelector('.js-drgn .link-hover-first').addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({
        top: offsetTopAnchor,
        behavior: 'smooth',
      });
    });
  }

}
