/* eslint-disable no-var */
window.addEventListener('load', () => {
  window.addEventListener('hashchange', () => {
    const anchor = window.location.hash;
    if (anchor) {
      const target = document.getElementById(anchor.substring(1));
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  });

  $(document).on('click', '.js-cards__item', function () {
    var url = $('a:first', this).attr('href');
    if (url) window.location = url;
    return false;
  });

  $(document).on('mousemove', '.js-swip-curs', function (e) {
    var p = $(this).offset();
    // eslint-disable-next-line prefer-template
    $(this)
      .find('.js-swip-curs-i')
      // eslint-disable-next-line prefer-template
      .css('left', e.pageX - p.left + 'px');
  });

  $('.js-popup-youtube').magnificPopup({
    disableOn: 0,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: true,
  });

  $('.js-order-popup').magnificPopup({
    disableOn: 0,
    type: 'ajax',
    // alignTop: true,
    fixedContentPos: true,
    // overflowY: 'scroll' // as we know that popup content is tall we set scroll overflow by default to avoid jump
    callbacks: {
      ajaxContentAdded() {
        if (document.querySelector('.js-order-form')) {
          // eslint-disable-next-line no-inner-declarations
          function validateEmail(mail) {
            const regEx =
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return regEx.test(String(mail).toLowerCase());
          }

          const orderForm = document.querySelector('.js-order-form');

          const inputName = orderForm.querySelector('.js-order-form-name');
          const inputTel = orderForm.querySelector('.js-order-form-tel');
          const inputMail = orderForm.querySelector('.js-order-form-mail');
          const subBtn = orderForm.querySelector('.js-order-form-btn');

          let inputNameResult = false;
          let inputTelResult = false;
          let inputMailResult = false;

          inputName.addEventListener('input', (e) => {
            const result = e.target.value.length;

            inputName.closest('label').classList.remove('error');
            inputNameResult = true;
            if (result <= 1) {
              inputName.closest('label').classList.add('error');
              inputNameResult = false;
            }
          });

          inputTel.addEventListener('input', (e) => {
            const maskOptions = {
              mask: `${inputTel.getAttribute('data-mask')}`,
              lazy: true,
            };
            // eslint-disable-next-line no-unused-vars, no-undef
            const mask = new IMask(inputTel, maskOptions);

            const result = e.target.value.length;

            inputTel.closest('label').classList.remove('error');
            inputTelResult = true;
            if (result < 17) {
              inputTel.closest('label').classList.add('error');
              inputTelResult = false;
            }
          });

          // eslint-disable-next-line no-unused-vars
          inputMail.addEventListener('input', function (e) {
            if (validateEmail(this.value)) {
              this.closest('label').classList.remove('error');
              inputMailResult = true;
            } else {
              this.closest('label').classList.add('error');
              inputMailResult = false;
            }
          });

          const formCheck = function () {
            if (inputNameResult === false) {
              inputName.closest('label').classList.add('error');
            }
            if (inputTelResult === false) {
              inputTel.closest('label').classList.add('error');
            }
            if (inputMailResult === false) {
              inputMail.closest('label').classList.add('error');
            }
          };

          $(subBtn).click(() => {
            if (inputNameResult && inputTelResult && inputMailResult) {
              subBtn.classList.add('form-disabled');
              $.ajax({
                url: orderForm.getAttribute('action'),
                type: 'post',
                dataType: 'json',
                data: $(subBtn).closest('form').serialize(),
                // eslint-disable-next-line func-names, object-shorthand
                success: function (data) {
                  if (data.status === 200) {
                    orderForm.innerHTML = data.html;
                  } else {
                    // eslint-disable-next-line no-undef
                    toastr.error('Ошибка');
                  }
                },
                // eslint-disable-next-line func-names, object-shorthand
                complete: function () {
                  subBtn.classList.remove('form-disabled');
                },
              });
            } else {
              formCheck();
              // eslint-disable-next-line no-undef
              toastr.error('Ошибка валидации');
            }
            return false;
          });
        }
      },
    },
  });

  $('.js-basket-popup').magnificPopup({
    disableOn: 0,
    type: 'ajax',
    // alignTop: true,
    fixedContentPos: true,
    // overflowY: 'scroll' // as we know that popup content is tall we set scroll overflow by default to avoid jump
    callbacks: {
      ajaxContentAdded() {
        if (document.querySelector('.js-basket-form')) {
          // eslint-disable-next-line no-inner-declarations
          function validateEmail(mail) {
            const regEx =
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return regEx.test(String(mail).toLowerCase());
          }

          const basketForm = document.querySelector('.js-basket-form');

          const inputName = basketForm.querySelector('.js-basket-form-name');
          const inputTel = basketForm.querySelector('.js-basket-form-tel');
          const inputMail = basketForm.querySelector('.js-basket-form-mail');
          const subBtn = basketForm.querySelector('.js-basket-form-btn');

          let inputNameResult = false;
          let inputTelResult = false;
          let inputMailResult = false;

          inputName.addEventListener('input', (e) => {
            const result = e.target.value.length;

            inputName.closest('label').classList.remove('error');
            inputNameResult = true;
            if (result <= 1) {
              inputName.closest('label').classList.add('error');
              inputNameResult = false;
            }
          });

          inputTel.addEventListener('input', (e) => {
            const maskOptions = {
              mask: `${inputTel.getAttribute('data-mask')}`,
              lazy: true,
            };
            // eslint-disable-next-line no-unused-vars, no-undef
            const mask = new IMask(inputTel, maskOptions);

            const result = e.target.value.length;

            inputTel.closest('label').classList.remove('error');
            inputTelResult = true;
            if (result < 17) {
              inputTel.closest('label').classList.add('error');
              inputTelResult = false;
            }
          });

          // eslint-disable-next-line no-unused-vars
          inputMail.addEventListener('input', function (e) {
            if (validateEmail(this.value)) {
              this.closest('label').classList.remove('error');
              inputMailResult = true;
            } else {
              this.closest('label').classList.add('error');
              inputMailResult = false;
            }
          });

          const formCheck = function () {
            if (inputNameResult === false) {
              inputName.closest('label').classList.add('error');
            }
            if (inputTelResult === false) {
              inputTel.closest('label').classList.add('error');
            }
            if (inputMailResult === false) {
              inputMail.closest('label').classList.add('error');
            }
          };

          $('.js-basket-form .js-basket-delete').click(function (event) {
            event.preventDefault();
            // eslint-disable-next-line vars-on-top
            var delBtn = $(this);
            subBtn.classList.add('form-disabled');
            $.ajax({
              url: delBtn.attr('href'),
              type: 'get',
              dataType: 'json',
              // eslint-disable-next-line func-names, object-shorthand
              success: function (data) {
                if (data.status === 200) {
                  delBtn.closest('.js-basket__item').remove();
                  if ($('.js-basket__item').length) {
                    $('.js-basket-cnt').html(data.basket_cnt);
                    $('.js-basket-price').html(data.basket_price);
                    $('.js-basket-enable').attr('style', '');
                  } else {
                    $('.js-basket-cnt').html(data.basket_cnt);
                    $('.js-basket-price').html(data.basket_price);
                    $('.js-basket-enable').attr('style', 'display: none');
                    $.magnificPopup.close();
                  }
                } else {
                  // eslint-disable-next-line no-undef
                  toastr.error('Ошибка');
                }
              },
              // eslint-disable-next-line func-names, object-shorthand
              complete: function () {
                subBtn.classList.remove('form-disabled');
              },
            });
            return false;
          });

          $(subBtn).click(() => {
            if (inputNameResult && inputTelResult && inputMailResult) {
              subBtn.classList.add('form-disabled');
              $.ajax({
                url: basketForm.getAttribute('action'),
                type: 'post',
                dataType: 'json',
                data: $(subBtn).closest('form').serialize(),
                // eslint-disable-next-line func-names, object-shorthand
                success: function (data) {
                  if (data.status === 200) {
                    basketForm.innerHTML = data.html;
                    $('.js-basket-cnt').html(0);
                    $('.js-basket-price').html(0);
                    $('.js-basket-enable').attr('style', 'display: none');
                  } else {
                    // eslint-disable-next-line no-undef
                    toastr.error('Ошибка');
                  }
                },
                // eslint-disable-next-line func-names, object-shorthand
                complete: function () {
                  subBtn.classList.remove('form-disabled');
                },
              });
            } else {
              formCheck();
              // eslint-disable-next-line no-undef
              toastr.error('Ошибка валидации');
            }
          });
        }
      },
    },
  });

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top,
      },
      500
    );
  });

  // eslint-disable-next-line vars-on-top
  var tmoku = null;
  // eslint-disable-next-line vars-on-top, camelcase
  var is_sndajx = false;
  $(document).on(
    'keyup',
    '.js-basket__item-inp-quant, .js-basket__item-inp-size',
    // eslint-disable-next-line no-unused-vars
    function (event) {
      var Btn = $(this);
      var Item = Btn.closest('.js-basket__item');

      clearTimeout(tmoku);
      // eslint-disable-next-line prefer-arrow-callback
      tmoku = setTimeout(function () {
        // eslint-disable-next-line camelcase
        if (!is_sndajx) {
          // eslint-disable-next-line camelcase
          is_sndajx = true;
          $.ajax({
            url: Btn.data('action'),
            type: 'post',
            dataType: 'json',
            // eslint-disable-next-line prefer-template
            data: Btn.attr('name') + '=' + Btn.val(),
            // eslint-disable-next-line func-names, object-shorthand
            success: function (data) {
              if (data.status === 200) {
                $('.js-basket-cnt').html(data.basket_cnt);
                $('.js-basket-price').html(data.basket_price);
                $('.js-basket-enable').attr('style', '');
                $('.js-basket__item-inp-quant', Item).val(data.item_cnt);
                $('.js-basket__item-inp-size', Item).val(data.item_size);
                $('.js-basket__item-price', Item).html(data.item_price);
              } else {
                // eslint-disable-next-line no-undef
                toastr.error('Ошибка');
              }
            },
            // eslint-disable-next-line func-names, object-shorthand
            complete: function () {
              // eslint-disable-next-line camelcase
              is_sndajx = false;
            },
          });
        }
      }, 500);
    }
  );
});
