import Swiper, { Autoplay } from 'swiper';

if (document.querySelector('section.product2')) {
  if ($(window).width() >= 1366) {
    document.querySelector('.js-product-gallary .swiper').style.height = `${
      document.querySelector('.js-info').getBoundingClientRect().height
    }px`;
  }

  // init Swiper:
  const swiper = new Swiper('.product2__swiper', {
    init: false,
    modules: [Autoplay],
    speed: 1000,
    // loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    breakpoints: {
      0: {
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 'auto',
      },
      1366: {
        direction: 'vertical',
        spaceBetween: 30,
        slidesPerView: 'auto',
      },
      1920: {
        spaceBetween: 50,
        direction: 'vertical',
        slidesPerView: 'auto',
      },
    },
  });

  swiper.init();
}
