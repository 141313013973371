import Swiper, {
  Pagination
} from 'swiper';

if(document.querySelector('section.gallary-video')){

  // init Swiper:

  document.querySelectorAll('.gallary-video__swiper').forEach((el) => {
    const swiper = new Swiper(el, {
      init: false,
      modules: [Pagination],
      pagination: {
        el: el.closest('.js-gallary-item').querySelector('.gallary-video__pagination'),
        type: 'progressbar',
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        1920: {
          spaceBetween: 20,
          slidesPerView: 3.2,
        },
      }
    });

    swiper.init();
  });
}
