if(window.matchMedia('(min-width: 1366px)').matches){
  (function () {

    const items = document.querySelectorAll('.js-categories-item');

    items.forEach((item, i) => {
      item.querySelectorAll('.js-img').forEach((img) => {

          	// init controller
            const controller = new ScrollMagic.Controller();

            // build tween
            const tween = TweenMax.to(img, 0.5, {
              y: '-100vh'
            });

            // build scene and set duration to window height
            const scene = new ScrollMagic.Scene({triggerElement: items[i], duration: $(window).height() * 3})
                    .setTween(tween)
                    // .addIndicators()
                    .addTo(controller);

      });
    });


  })();
}
