import Swiper, { Pagination, FreeMode } from 'swiper';

if (
  document.querySelector('section.price-list') ||
  document.querySelector('section.price-list2')
) {
  // init Swiper:
  const activeSlide = document.querySelector('.swiper-slide.active');
  const index = Array.from(activeSlide.parentElement.children).indexOf(
    activeSlide
  );
  console.log(index);

  const swiper = new Swiper('.swiper.price-list', {
    init: false,
    modules: [Pagination, FreeMode],
    freeMode: true,
    pagination: {
      el: '.swiper-pagination.price-list',
      type: 'progressbar',
    },
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 20,
      },
      1366: {
        slidesPerView: 'auto',
        spaceBetween: 32,
      },
    },
    on: {
      // eslint-disable-next-line
      afterInit: function (swiper) {
        if (index) {
          swiper.slideTo(index);
        }
      },
    },
  });

  swiper.init();

  const mainDDbtns = document.querySelectorAll('.js-filter-btn');
  const mainDDlist = document.querySelectorAll('.js-filter-list');

  mainDDbtns.forEach((el, idx) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();

      mainDDlist.forEach((elem, index) => {
        if (index !== idx) {
          $(elem).slideUp('slow', () => {
            $(elem).prev().removeClass('active');
          });
        } else {
          $(elem).slideToggle('slow', () => {
            $(elem).prev().toggleClass('active');
          });
        }
      });
    });
  });

  $('body').click((event) => {
    if (
      !$(event.target).closest('.js-filters').length &&
      !$(event.target).is('.js-filters')
    ) {
      mainDDlist.forEach((elem) => {
        $(elem).slideUp('slow', () => {
          $(elem).prev().removeClass('active');
        });
      });
    }
  });

  $(document).on('keyup', (e) => {
    if (e.key === 'Escape') {
      mainDDlist.forEach((elem) => {
        $(elem).slideUp('slow', () => {
          $(elem).prev().removeClass('active');
        });
      });
    }
  });

  mainDDlist.forEach((el) => {
    el.querySelectorAll('.select a').forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const input = link
          .closest('form')
          .querySelector('input[type="hidden"]');
        input.value = link.getAttribute('data-value');
        mainDDlist.forEach((elem) => {
          $(elem).slideUp('slow', () => {
            $(elem).prev().removeClass('active');
          });
        });
        link.closest('form').submit();
      });
    });
  });

  const btnToggle = document.querySelector('.js-basket-toggle');
  const hideToggle = document.querySelector('.js-hide');

  btnToggle.addEventListener('click', (e) => {
    e.preventDefault();
    btnToggle.classList.toggle('active');
    $(hideToggle).animate({ width: 'toggle' });
  });
  // eslint-disable-next-line func-names
  $(document).on('click', '.js-add-to-basket', function () {
    // eslint-disable-next-line no-var
    var form = $(this).closest('form');

    $.ajax({
      url: form.attr('action'),
      type: 'post',
      dataType: 'json',
      data: form.serialize(),
      // eslint-disable-next-line func-names, object-shorthand
      success: function (data) {
        if (data.status === 200) {
          $('.js-basket-cnt').html(data.basket_cnt);
          $('.js-basket-price').html(data.basket_price);
          $('.js-basket-enable').attr('style', '');
        } else {
          // eslint-disable-next-line no-undef
          toastr.error('Ошибка');
        }
      },
    });

    return false;
  });
}
