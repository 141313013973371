const mainDDbtns = document.querySelectorAll('.js-btn');
const mainDDlist = document.querySelectorAll('.js-main-dd');

mainDDbtns.forEach((el, idx) => {
  if (window.matchMedia('(max-width: 1440px)').matches) {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      mainDDlist.forEach((elem, index) => {
        if (index !== idx) {
          $(elem).slideUp('slow', () => {
            $(elem).prev().find('.btn').removeClass('active');
          });
        } else {
          $(elem).slideToggle('slow', () => {
            $(elem).prev().find('.btn').toggleClass('active');
          });
        }
      });
    });
  }
});

$('body').click((event) => {
  if (
    !$(event.target).closest('.js-main-menu').length &&
    !$(event.target).is('.js-main-menu')
  ) {
    mainDDlist.forEach((elem) => {
      $(elem).slideUp('slow', () => {
        $(elem).prev().find('.btn').removeClass('active');
      });
    });
  }
});

$(document).on('keyup', (e) => {
  if (e.key === 'Escape') {
    mainDDlist.forEach((elem) => {
      $(elem).slideUp('slow', () => {
        $(elem).prev().find('.btn').removeClass('active');
      });
    });
  }
});
