/* eslint-disable no-inner-declarations */
if (document.querySelector('.map')) {
  // https://yandex.ru/dev/maps/jsbox/2.1/route_panel_control/
  function init() {
    const map = document.getElementById('map');
    if (!map) return;
    const { lat, lng, zoom, icon, name, time, stars } = map.dataset;

    // eslint-disable-next-line no-undef
    const myMap = new ymaps.Map('map', {
      center: [lat, lng],
      // eslint-disable-next-line object-shorthand
      zoom: zoom,
      balloonMaxWidth: 150,
      // Добавим панель маршрутизации.
      controls: ['routePanelControl'],
    });

    // eslint-disable-next-line no-undef
    const iconPlace = new ymaps.Placemark(
      [lat, lng],
      {
        balloonContentHeader: `<b>${name}</b>`,
        balloonContentBody: `${time}  <b>★${stars}</b>`,
        // balloonContentFooter: 'Подвал',
      },
      {
        iconLayout: 'default#image',
        iconImageHref: icon,

        iconImageOffset: [-24, -24],
        balloonShadow: false,
        hideIconOnBalloonOpen: false,
        balloonOffset: [36, -12],
        iconImageSize: [48, 48],
      }
    );

    myMap.geoObjects.add(iconPlace);

    const control = myMap.controls.get('routePanelControl');
    control.options.set({ autofocus: false });

    // eslint-disable-next-line no-undef
    const switchPointsButton = new ymaps.control.Button({
      data: { content: 'Поменять местами', title: 'Поменять точки местами' },
      options: { selectOnClick: false, maxWidth: 160 },
    });
    // Объявляем обработчик для кнопки.
    // eslint-disable-next-line prefer-arrow-callback
    switchPointsButton.events.add('click', function () {
      // Меняет местами начальную и конечную точки маршрута.
      control.routePanel.switchPoints();
    });
    myMap.controls.add(switchPointsButton);

    myMap.behaviors.disable('scrollZoom');

    myMap.controls.add('zoomControl', {
      position: {
        top: 15,
        right: 15,
      },
    });

    control.routePanel.state.set({
      type: 'masstransit',
      fromEnabled: true,
      toEnabled: false,
      to: 'Московская обл., Волоколамский район, деревня Кашино.',
    });

    control.routePanel.options.set({
      allowSwitch: false,
      // reverseGeocoding: true,
      types: { masstransit: true, pedestrian: true, taxi: true },
    });

    // const routeTrigger = document.querySelector('.js-get-route');

    // routeTrigger.addEventListener('click', () => {
    //   control.routePanel.state.set({
    //     type: 'masstransit',
    //     fromEnabled: true,
    //     toEnabled: false,
    //     to: 'Московская обл., Волоколамский район, деревня Кашино.',
    //   });

    //   control.routePanel.options.set({
    //     allowSwitch: false,
    //     // reverseGeocoding: true,
    //     types: { masstransit: true, pedestrian: true, taxi: true },
    //   });
    // });
  }

  // eslint-disable-next-line no-undef
  ymaps.ready(init);
}

if (document.querySelector('.js-main-form')) {
  function validateEmail(mail) {
    const regEx =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return regEx.test(String(mail).toLowerCase());
  }

  const mainForm = document.querySelector('.js-main-form');

  const inputName = document.querySelector('.js-main-form-name');
  const inputTel = document.querySelector('.js-main-form-tel');
  const inputMail = document.querySelector('.js-main-form-mail');
  const subBtn = mainForm.querySelector('button[type="button"]');

  let inputNameResult = false;
  let inputTelResult = false;
  let inputMailResult = false;

  inputName.addEventListener('input', (e) => {
    const result = e.target.value.length;

    inputName.closest('label').classList.remove('error');
    inputNameResult = true;
    if (result <= 1) {
      inputName.closest('label').classList.add('error');
      inputNameResult = false;
    }
  });

  inputTel.addEventListener('input', (e) => {
    const maskOptions = {
      mask: `${inputTel.getAttribute('data-mask')}`,
      lazy: true,
    };
    // eslint-disable-next-line no-unused-vars, no-undef
    const mask = new IMask(inputTel, maskOptions);

    const result = e.target.value.length;

    inputTel.closest('label').classList.remove('error');
    inputTelResult = true;
    if (result < 17) {
      inputTel.closest('label').classList.add('error');
      inputTelResult = false;
    }
  });

  // eslint-disable-next-line no-unused-vars
  inputMail.addEventListener('input', function (e) {
    if (validateEmail(this.value)) {
      this.closest('label').classList.remove('error');
      inputMailResult = true;
    } else {
      this.closest('label').classList.add('error');
      inputMailResult = false;
    }
  });

  const formCheck = function () {
    if (inputNameResult === false) {
      inputName.closest('label').classList.add('error');
    }
    if (inputTelResult === false) {
      inputTel.closest('label').classList.add('error');
    }
    if (inputMailResult === false) {
      inputMail.closest('label').classList.add('error');
    }
  };

  $(subBtn).click(() => {
    if (inputNameResult && inputTelResult && inputMailResult) {
      subBtn.classList.add('form-disabled');
      $.ajax({
        url: $(subBtn).closest('form').attr('action'),
        type: 'post',
        dataType: 'json',
        data: $(subBtn).closest('form').serialize(),
        /* eslint-disable */
        success: function (data) {
          if (data.status === 200) {
            toastr.success('Заявка принята');
          } else {
            toastr.error('Ошибка');
            subBtn.classList.remove('form-disabled');
          }
        },
        complete: function (e, status) {
          if (status != 'success') {
            toastr.error('Ошибка');
            subBtn.classList.remove('form-disabled');
          }
          //			subBtn.classList.remove('form-disabled');
        },
        /* eslint-enable */
      });
    } else {
      formCheck();
      // eslint-disable-next-line no-undef
      toastr.error('Ошибка валидации');
    }
  });
}
