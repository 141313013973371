import Swiper, { Navigation, Scrollbar } from 'swiper';

// init Swiper:
const swiper = new Swiper('.partner__swiper', {
  init: false,
  modules: [Navigation, Scrollbar],
  // pagination: {
  //   el: '.partner__pagination',
  //   type: 'progressbar',
  // },
  navigation: {
    prevEl: `.swiper-button-prev`,
    nextEl: `.swiper-button-next`,
  },
  scrollbar: {
    el: `.swiper-scrollbar`,
    draggable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1.2,
      spaceBetween: 15,
    },
    768: {
      slidesPerView: 2.2,
      spaceBetween: 30,
    },
    1920: {
      spaceBetween: 40,
      slidesPerView: 2.5,
    },
  },
  on: {
    // eslint-disable-next-line func-names
    init() {
      const container = document
        .querySelector('.partner__swiper')
        .parentNode.querySelector(`.swiper-scrollbar`);
      const lenght = document
        .querySelector('.partner__swiper')
        .querySelectorAll('.swiper-slide').length;
      const span = document.createElement('span');
      span.classList.add('move');
      container.prepend(span);
      // eslint-disable-next-line prefer-arrow-callback
      swiper.on('slideChange', function () {
        const parentWidth = window
          .getComputedStyle(container)
          .getPropertyValue('width');
        container.querySelector('.move').style.width = `${
          (parseFloat(parentWidth) * (swiper.activeIndex + 1)) / lenght
        }px`;
      });
    },
  },
});

swiper.init();
