import Swiper, {
  Pagination
} from 'swiper';

// init Swiper:
const swiper = new Swiper('.partners__swiper', {
  init: false,
  modules: [Pagination],
  slidesPerView: 6,
  pagination: {
    el: '.partners__pagination',
    type: 'progressbar',
  },
  breakpoints: {
    640: {
      spaceBetween: 20,
    },
    768: {
      spaceBetween: 40,
    },
    1920: {
      spaceBetween: 73,
    },
  }
});

swiper.init();
