if (document.querySelector('section.garden-gallery')) {
  $('#myMosaic').Mosaic({
    innerGap: 10,
    maxRowHeight: 400,
    refitOnResize: true,
    refitOnResizeDelay: true,
    defaultAspectRatio: 0.5,
    maxRowHeightPolicy: 'crop',
    // highResImagesWidthThreshold: 850,
    // responsiveWidthThreshold: 500
  });
}

const galleryCount = document.querySelectorAll('[class*=js-gallery-popup]');

if (galleryCount[0]) {
  console.log(galleryCount);
  galleryCount.forEach((item, i) => {
    $(`.js-gallery-popup${i}`).magnificPopup({
      delegate: `a`,
      type: 'image',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
      },
    });
  });
}
