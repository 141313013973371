if (document.querySelector('section.catalog-item')) {
  const selectBtn = document.querySelectorAll('.js-select');
  const optionsList = document.querySelectorAll('.js-choice');
  const sortInputs = document.querySelectorAll('.js-sort input');
  const sortForm = document.querySelectorAll('.js-sort');

  selectBtn.forEach((el, idx) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();

      optionsList.forEach((elem, index) => {
        if (index !== idx) {
          $(elem).slideUp('slow', () => {
            $(elem).css('zIndex', '0');
            $(elem).prev().removeClass('active');
          });
        } else {
          $(elem).css('zIndex', '1');
          $(elem).slideToggle('slow', () => {
            $(elem).prev().toggleClass('active');
          });
        }
      });
    });
  });

  $('body').click((event) => {
    if (
      !$(event.target).closest('.js-selects').length &&
      !$(event.target).is('.js-selects')
    ) {
      optionsList.forEach((elem) => {
        $(elem).slideUp('slow', () => {
          $(elem).css('zIndex', '0');
          $(elem).prev().removeClass('active');
        });
      });
    }
  });

  $(document).on('keyup', (e) => {
    if (e.key === 'Escape') {
      optionsList.forEach((elem) => {
        $(elem).slideUp('slow', () => {
          $(elem).css('zIndex', '0');
          $(elem).prev().removeClass('active');
        });
      });
    }
  });

  optionsList.forEach((el) => {
    el.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-param-reassign
        link.closest('.selects__item').querySelector('.name').innerHTML =
          link.innerHTML;
        sortInputs.forEach((sort) => {
          if (sort.getAttribute('data-name') === el.getAttribute('data-type')) {
            sort.setAttribute('value', link.getAttribute('data-value'));
          }
        });
        optionsList.forEach((elem) => {
          $(elem).slideUp('slow', () => {
            $(elem).css('zIndex', '0');
            $(elem).prev().removeClass('active');
          });
        });
        sortForm.forEach((element) => {
          element.submit();
        });
      });
    });
  });
}
