const searchBtn = document.querySelector('.js-search-toggle');

searchBtn.addEventListener('click', (e) => {
  e.preventDefault();
  $(searchBtn).next().toggle('slide');
  searchBtn.classList.toggle('active');
});

$('body').click((event) => {
  if (
    !$(event.target).closest('.js-search').length &&
    !$(event.target).is('.js-search')
  ) {
    $(searchBtn).next().hide('slide');
    searchBtn.classList.remove('active');
  }
});

$(document).on('keyup', (e) => {
  if (e.key === 'Escape') {
    $(searchBtn).next().hide('slide');
    searchBtn.classList.remove('active');
  }
});

const soundBtn = document.querySelector('.js-sound');
const sound = new Audio('/mp3/nivaki.mp3');
sound.addEventListener(
  'ended',
  function () {
    this.currentTime = 0;
    this.play();
  },
  false
);

let soundPlay = false;

soundBtn.addEventListener('click', function (e) {
  e.preventDefault();
  if (soundPlay) {
    sound.pause();
    sound.currentTime = 0;
    soundPlay = false;
    this.classList.remove('active');
  } else {
    sound.play();
    soundPlay = true;
    this.classList.add('active');
  }
});

const menuBtn = document.querySelector('.js-menu-btn');
const header = document.querySelector('.js-header');
const dom = document.querySelector('html');

menuBtn.addEventListener('click', function (e) {
  e.preventDefault();

  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  this.classList.toggle('active');
  header.classList.toggle('menu-open');
  dom.classList.toggle('disabled');
});
