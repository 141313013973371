import Swiper, { Pagination, Scrollbar } from 'swiper';

// init Swiper:
const swiper = new Swiper('.nursery-gallery__swiper', {
  init: false,
  modules: [Pagination, Scrollbar],
  slidesPerView: 'auto',
  // pagination: {
  //   el: '.nursery-gallery__pagination',
  //   type: 'progressbar',
  // },
  scrollbar: {
    el: '.nursery-gallery__pagination',
    draggable: true,
  },
  breakpoints: {
    0: {
      spaceBetween: 10,
    },
    768: {
      spaceBetween: 20,
    },
    1365: {
      spaceBetween: 40,
    },
  },
  // eslint-disable-next-line
  init: function () {
    const container = el.parentNode.querySelector(`.swiper-scrollbar${i}`);
    const lenght = el.querySelectorAll('.swiper-slide').length;
    const span = document.createElement('span');
    span.classList.add('move');
    container.prepend(span);
    // eslint-disable-next-line prefer-arrow-callback
    swiper.on('slideChange', function () {
      const parentWidth = window
        .getComputedStyle(container)
        .getPropertyValue('width');
      container.querySelector('.move').style.width = `${
        (parseFloat(parentWidth) * (swiper.activeIndex + 1)) / lenght
      }px`;
    });
  },
});

swiper.init();
