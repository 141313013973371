if (document.querySelector('.js-pagen-more')) {
  $(document).on('click', '.js-pagen-more', function () {
    // eslint-disable-next-line no-var
    var cont = $(this);
    if (!cont.hasClass('js-loading')) {
      cont.addClass('js-loading');
      cont.css('opacity', '0.5');
      $.ajax({
        url: $(this).attr('href'),
        method: 'get',
        // eslint-disable-next-line object-shorthand, func-names
        success: function (data) {
          $('.js-pagen-cont').append($('.js-pagen-cont', data).html());
          if (!$('.js-pagen-more', data).length) {
            cont.hide();
          } else {
            cont.replaceWith($('.js-pagen-more', data));
          }
        },
      });
    }
    return false;
  });
}
