/* eslint-disable object-shorthand */
import Swiper, { Navigation, Scrollbar } from 'swiper';

if (document.querySelector('section.gallary-photo')) {
  document.querySelectorAll('.gallary-photo__swiper').forEach((el, i) => {
    const swiper = new Swiper(el, {
      init: false,
      modules: [Navigation, Scrollbar],
      slidesPerView: 'auto',
      // pagination: {
      //   el: el
      //     .closest('.js-gallary-item')
      //     .querySelector('.gallary-photo__pagination'),
      //   type: 'progressbar',
      // },
      navigation: {
        prevEl: `.swiper-button-prev${i}`,
        nextEl: `.swiper-button-next${i}`,
      },
      scrollbar: {
        el: `.swiper-scrollbar${i}`,
        draggable: true,
      },
      // breakpoints: {
      //   0: {
      //     slidesPerView: 1.2,
      //     spaceBetween: 15,
      //   },
      //   768: {
      //     slidesPerView: 2.2,
      //     spaceBetween: 20,
      //   },
      //   1920: {
      //     spaceBetween: 20,
      //     slidesPerView: 3.2,
      //   },
      // },
      breakpoints: {
        0: {
          spaceBetween: 10,
        },
        768: {
          spaceBetween: 20,
        },
        1365: {
          spaceBetween: 40,
        },
      },
      on: {
        // eslint-disable-next-line func-names
        init: function () {
          const container = el.parentNode.querySelector(
            `.swiper-scrollbar${i}`
          );
          const lenght = el.querySelectorAll('.swiper-slide').length;
          const span = document.createElement('span');
          span.classList.add('move');
          container.prepend(span);
          // eslint-disable-next-line prefer-arrow-callback
          swiper.on('slideChange', function () {
            const parentWidth = window
              .getComputedStyle(container)
              .getPropertyValue('width');
            container.querySelector('.move').style.width = `${
              (parseFloat(parentWidth) * (swiper.activeIndex + 1)) / lenght
            }px`;
          });
        },
      },
    });

    swiper.init();
  });
}
